import { Injectable } from "@angular/core";
import { Observable, of } from 'rxjs';
import { APIService } from "../application/api.service";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class TestService {
    public get serviceName(): string { return "Test" };

    constructor(private api: APIService) {
    }

    public checkStatus(): Observable<any> {
        const url = "/environment/checkstatus";
        return this.api.get(null, url);
    }
}