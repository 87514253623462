import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UIBehavior } from '../../ui/ui-behavior';
declare var $: any;
@Injectable()
export class ViewLoader implements Resolve<boolean> {
    public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        $('.tooltip').tooltip('hide')
        return true;
    }
}