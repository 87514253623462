import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Message } from 'src/app/framework/message';
import { MessageService } from 'src/app/services/application/message.service';
import { environment } from 'src/environments/environment';
import * as FW from "src/app/framework/core";
import { WarningLevel } from 'src/app/framework/enums';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {
  @Input() container: string;

  public helpEmailAddress: string = null;
  private messageSubscription: Subscription = null;

  private _messageList: Array<Message> = null;

  public get messageList(): Array<Message> {
    return this._messageList;
  }

  constructor(public messageService: MessageService) {
    this._messageList = new Array<Message>();

    this.helpEmailAddress = environment.vendorEmailAddress;

    this.messageSubscription = this.messageService.onMessageReceived.subscribe((msg: Message) => {
      if (FW.isNull(msg)) {
        this.clear();
      } else if (msg.warningLevel == WarningLevel.Info) {
        this.clear();
      } else if (msg.container == this.container) {
        this.messageList.push(msg);
      }
    });
  }

  public dismiss(index: number): void {
    if (!FW.isNullOrEmpty(this.messageList)) {
      this.messageList.splice(index, 1);
    }
  }

  public clear() {
    this._messageList = new Array<Message>();
  }

  public ngOnInit() {
  }

  public ngOnDestroy(): void {
    this.messageSubscription.unsubscribe();
  }
}
