import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";
import { ISegmentation } from 'src/app/models/ISegmentation';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { ISegmentationAsset } from 'src/app/models/ISegmentationAsset';
@Injectable({
    providedIn: 'root'
})
export class SegmentationService extends BaseService {

    private baseUrl: string;
    public get serviceName(): string { return "Segmentation" };
    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = "/segmentation";
    }

    search(params: IQueryParams[]): Observable<FW.Envelope<ISegmentation[]>> {
        const callURL = super.fabricateURL(`/segmentation/search`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        if (callURL) {
            return this.apiService.get(ctx, callURL)
        } else {
            return of(null)
        }
    }

    public read(segmentationId: string): Observable<ISegmentation> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: '' }
        ]
        

        const callURL = super.fabricateURL(`/domain${this.baseUrl}/read`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, callURL);
    }

    public exclude(segmentationId: string): Observable<IEnvelope<boolean>> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: 'id' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/exclude`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.delete(ctx, callURL);
    }

    public save(entity: ISegmentation): Observable<IEnvelope<ISegmentation>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Nome", entity.name);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/save`, postEnvelope);
    }

    public send(entity: ISegmentation): Observable<IEnvelope<ISegmentation>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Nome", entity.name);
        validator.requestField("Código", entity.code);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/send`, postEnvelope);
    }

    public approve(entity: ISegmentation): Observable<IEnvelope<ISegmentation>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Nome", entity.name);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/approve`, postEnvelope);
    }

    searchDocument(params: IQueryParams[]): Observable<ISegmentationAsset[]> {
        const callURL = super.fabricateURL(`${this.baseUrl}/searchDocument/`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);


        if (callURL) {
            return this.apiService.get<ISegmentationAsset[]>(ctx, callURL)
        } else {
            return of(null)
        }
    }

    downloadDocument(id: any): Observable<FW.Envelope<ISegmentation>> {
        const ctx: IAppContext = this.context.join(this.serviceName);

        return this.apiService.get(ctx, `${this.baseUrl}/downloadDocument/${id}`)
    }

    public getDisagreements(id: any): Observable<IEnvelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, `/batchfile/getdisagreements/${id}`);
    }

}
