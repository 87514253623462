export class Session {
    public static getString(key: string, defaultValue?: string): string {
        let result: string = sessionStorage.getItem(key);
        return (result || defaultValue);
    }

    public static setString(key: string, value: string): void {
        sessionStorage.removeItem(key);

        if (value != null) {
            sessionStorage.setItem(key, value);
        }
    }

    public static getNumber(key: string): number {
        let result: string = Session.getString(key);

        if (null != result) {
            return parseInt(result);
        } else {
            return null;
        }
    }

    public static setNumber(key: string, value: number): void {
        sessionStorage.removeItem(key);

        if (value != null) {
            sessionStorage.setItem(key, value.toString());
        }
    }

    public static getDate(key: string): Date {
        let result: string = Session.getString(key);

        if (null != result) {
            return new Date(result);
        } else {
            return null;
        }
    }

    public static setDate(key: string, value: Date): void {
        sessionStorage.removeItem(key);

        if (value != null) {
            Session.setString(key, value.toISOString());
        }
    }

    public static clear() {
        sessionStorage.clear();
    }
}