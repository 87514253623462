import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/security/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { ViewLoader } from './services/application/viewloader.service';
import { LockScreenComponent } from './pages/security/lockscreen/lockscreen.component';
import { RouteGuardService } from './services/application/routeguard.service';


const routes: Routes = [
  { path: "", redirectTo: "main", pathMatch: "full" },
  { path: "main", loadChildren: "./pages/main/main.module#MainModule", canActivate: [RouteGuardService], resolve: { loader: ViewLoader } },
  { path: "login", component: LoginComponent, resolve: { loader: ViewLoader } },
  { path: "lock", component: LockScreenComponent, resolve: { loader: ViewLoader } },
  { path: '**', component: NotFoundComponent, resolve: { loader: ViewLoader } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
