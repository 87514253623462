import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { LoginComponent } from './pages/security/login/login.component';
import { AppControlsModule } from './controls/app-controls.module';
import { ViewLoader } from './services/application/viewloader.service';
import { httpInterceptorProviders } from './http-interceptors/interceptorprovider';
import { serviceProviders } from './services/serviceprovider';
import { HttpClientModule } from '@angular/common/http';
import { LockScreenComponent } from './pages/security/lockscreen/lockscreen.component';

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    LoginComponent,
    LockScreenComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AppControlsModule,
    HttpClientModule
  ],
  providers: [
    httpInterceptorProviders,
    serviceProviders,
    ViewLoader
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
