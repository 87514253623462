import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { ContextService } from "./context.service";

@Injectable({
    providedIn: 'root',
})
export class RouteGuardService implements CanActivate {

    constructor(private context: ContextService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        
        const hasAuthData: boolean = this.context.authService.tryToRestoreSession();

        const redirectToUrl: string = state.url;
        let scope = null;
        if(this.context.navigation.hasQueryParameter("scope")) {
            scope = encodeURIComponent(this.context.navigation.getQueryParameter("scope"));
        }
        if (hasAuthData) {
            if (!this.context.authService.isSessionActive) {
                console.log("Session expired!");
                this.context.navigation.changeRoute("/lock", { redirectTo: redirectToUrl, "scope": scope });
                return false;
            }
        } else {
            
            console.log("Authentication required!");
            this.context.navigation.changeRoute("/login", { redirectTo: redirectToUrl, "scope": scope });
            return false;
        }

        return true;
    }
}