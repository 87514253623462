import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { IBroadcastMessage } from 'src/app/models/IBroadcastMessage';
import { Observable, of } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root'
})

export class EmailLogService extends BaseService {

    private baseUrl: string;
    public get serviceName(): string { return "EmailLog" };
    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = "/emaillog";
    }

    sendEmail(scopeKey: string, messageId: string, recipients: string): Observable<FW.Envelope<IBroadcastMessage[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, `${this.baseUrl}/saveemail/${messageId}?recipients=${recipients}&scopeKey=${scopeKey}`)
    }

    sendEmailNF(scopeKey: string, messageId: string, recipients: string): Observable<FW.Envelope<IBroadcastMessage[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, `${this.baseUrl}/saveemailnf/${messageId}?recipients=${recipients}&scopeKey=${scopeKey}`)
    }

    sendEmailToken(scopeKey: string, userAccessId: string, recipients: string): Observable<FW.Envelope<IBroadcastMessage[]>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, `${this.baseUrl}/saveemailtoken/${userAccessId}?recipients=${recipients}&scopeKey=${scopeKey}`)
    }


}
