export class Storage {
    public static getString(key: string, defaultValue?: string): string {
        let result: string = localStorage.getItem(key);
        return (result || defaultValue);
    }

    public static setString(key: string, value: string): void {
        localStorage.removeItem(key);

        if (value != null) {
            localStorage.setItem(key, value);
        }
    }

    public static getNumber(key: string): number {
        let result: string = Storage.getString(key);

        if (null != result) {
            return parseInt(result);
        } else {
            return null;
        }
    }

    public static setNumber(key: string, value: number): void {
        localStorage.removeItem(key);

        if (value != null) {
            localStorage.setItem(key, value.toString());
        }
    }

    public static getDate(key: string): Date {
        let result: string = Storage.getString(key);

        if (null != result) {
            return new Date(result);
        } else {
            return null;
        }
    }

    public static setDate(key: string, value: Date): void {
        localStorage.removeItem(key);

        if (value != null) {
            Storage.setString(key, value.toISOString());
        }
    }

    public static clear() {
        localStorage.clear();
    }
}