import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';

import { finalize, tap } from 'rxjs/operators';

import { LoaderService } from '../services/application/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
    private static _currentCalls: number = 0;

    constructor(private loaderService: LoaderService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        LoaderInterceptor._currentCalls++;

        this.loaderService.displayLoader();

        return next.handle(req).pipe(
            finalize(() => {
                LoaderInterceptor._currentCalls--;

                if (LoaderInterceptor._currentCalls <= 0) {
                    LoaderInterceptor._currentCalls = 0;
                    this.loaderService.hideLoader();
                }
            })
        );
    }
}