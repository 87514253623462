declare var $: any;
declare var initApp: any;
import * as FW from "../framework/core";

declare function displayToast(title: string, message: string, type: string): any;
declare function changeTheme(scopeTheme: string): void;
declare function startBootstrapTooltips(): void;

export class UIBehavior {

    static runInitFunctions() {
        this.addDeviceType();
        this.detectBrowserType();
        this.mobileCheckActivation();
        this.buildNavigation();
        this.listFilter();
        this.domReadyMisc();
        this.appForms();
    }

    static addDeviceType() {
        initApp.addDeviceType();
    }

    static detectBrowserType() {
        initApp.detectBrowserType()
    }

    static mobileCheckActivation() {
        initApp.mobileCheckActivation();
    }

    static buildNavigation() {
        const navHooks = $('#js-nav-menu');
        initApp.buildNavigation(navHooks)
    }

    static listFilter() {
        const navHooks = $('#js-nav-menu');
        const navFilterInput = $('#nav_filter_input');
        const navAnchor = $('#js-primary-nav');
        initApp.listFilter(navHooks, navFilterInput, navAnchor);
    }

    static domReadyMisc() {
        initApp.domReadyMisc();
    }

    static appForms() {
        initApp.appForms('.input-group', 'has-length', 'has-disabled');
    }


    static notify(title: string, message: string, type: "info" | "success" | "warning" | "error"): void {

        if (!FW.isNullOrBlank(title)) {
            title = `<strong>${title}</strong>`
        } else {
            title = null;
        }

        displayToast(title, message, type);
    }

    static changeTheme(scopeTheme: string) {
        changeTheme(scopeTheme);
    }

    static startBootstrapTooltips() {
        startBootstrapTooltips();
    }
}