import { Injectable } from '@angular/core';

declare var Pace: any;

@Injectable({
    providedIn: 'root'
})
export class LoaderService {

    constructor() {
    }

    public displayLoader(): void {
        Pace.start();
    }

    public hideLoader() {
        Pace.stop();
    }
}