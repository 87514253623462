import { IValidator, IEnvelope } from "./interfaces";
import { WarningLevel } from "./enums";

declare function moment(value: string | Date): any;

export function isNull(value: any): boolean {
    return ((value == null) || (value == undefined));
}

export function isNullOrEmpty(value: any): boolean {
    if (isNull(value)) { return true; }

    if (typeof value == "string") {
        return (value.length == 0);
    } else if (value instanceof Array) {
        return (value.length == 0);
    }

    return false;
}

export function isNullOrBlank(value: string): boolean {
    if (isNullOrEmpty(value)) { return true; }
    if (typeof value == "string") {
        return (value.trim().length == 0);
    } else {
        return false;
    }
}

export function generateNewGUID(): string {
    var s4 = function () {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export function randomize(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function formatDate(value: string | Date, format: string) {
    return moment(value).format(format);
}

export class ValidationContext {
    private innerValidator: IValidator = null;
    public isValid: boolean = true;

    constructor(validator: IValidator) {
        this.innerValidator = validator;
    }

    public requestField(fieldName: string, fieldValue: string | number): void {
        if (!this.innerValidator.requestField(fieldName, fieldValue)) {
            this.isValid = false;
        }
    }

    public verifyDate(fieldName: string, fieldValue: string): void {
        if (!this.innerValidator.verifyDate(fieldName, fieldValue)) {
            this.isValid = false;
        }
    }

    public compare(fieldNameA: string, fieldValueA: string, fieldNameB: string, fieldValueB: string): void {
        if (!this.innerValidator.compare(fieldNameA, fieldValueA, fieldNameB, fieldValueB)) {
            this.isValid = false;
        }
    }
}

export class Envelope<T> implements IEnvelope<T> {
    public content: T = null;
    public warningLevel: WarningLevel = null;
    public message: string;
    public pageSize: number;
    public pageIndex: number;
    public pageCount: number;
    public totalCount: number;

    constructor(data: T) {
        this.content = data;
    }
}

export class PropertyMap {
    public label: string = null;
    public propertyName: string = null;
    public propertyType: "string" | "number" | "date" = "string";

    public static create(label: string, propertyName: string, propertyType: "string" | "number" | "date" = "string"): PropertyMap {
        return {
            "label": label,
            "propertyName": propertyName,
            "propertyType": propertyType
        };
    }
}