import { Injectable } from '@angular/core';
import { IMessage } from 'src/app/models/IMessage';

declare var Swal: any;

@Injectable({
    providedIn: 'root'
})
export class MessageSwalService {

    constructor() { }

    displayMessage(msg: IMessage, callback?: any): void {
        switch (msg.warninglevel) {
            case 0:
                if (callback) {
                    Swal.fire('Sucesso', msg.message, 'success').then(callback);
                } else {
                    Swal.fire('Sucesso', msg.message, 'success');
                }
                break;
            case 1:
                Swal.fire('Alerta', msg.message, 'warning');
                break;
            case 2:
                Swal.fire('Erro', msg.message, 'error');
                break;
            default:
                Swal.fire('Erro', msg.message, 'error');
                break;
        }
    }
}
