import { Injectable } from "@angular/core";

import { ITypeReference } from "../../models/ITypereference";

import * as FW from "../../framework/core";
import { IEntity } from "../../models/IEntity";

@Injectable({
    providedIn: 'root',
})
export class TipifierService {
    public CONTACT: ITypeReference = {
        key: "a1f9a763-f747-4474-92cf-fbdf3e396922",
        icon: "",
        name: "Contact",
        title: "Contato",
        url: ""
    };

    public COMPANY: ITypeReference = {
        key: "686f7ff7-ac43-435b-b34d-710450ac67f5",
        icon: "",
        name: "Company",
        title: "Empresa",
        url: ""
    };

    private _typeMap: any = null;

    constructor() {
        this._typeMap = {};

        this._typeMap[this.CONTACT.key] = this.CONTACT;
        this._typeMap[this.COMPANY.key] = this.COMPANY;
    }

    public parse(typeReference: string): ITypeReference {
        return this._typeMap[typeReference];
    }

    public getTypeReference(testingEntity: IEntity): ITypeReference {
        if (FW.isNull(testingEntity)) { return null; }
        return this.parse(testingEntity.typeReference);
    }

    public isTypeOf(testingEntity: IEntity, ref: string) {
        const reference: ITypeReference = (this[ref] as ITypeReference);
        return (reference.key == testingEntity.typeReference);
    }
}