import { Injectable } from "@angular/core";

import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    private tempCache: any = {};

    public containsTemporaryKey(key: string): boolean {
        return (key in this.tempCache);
    }

    public getTemporaryValue<T>(key: string): T {
        if (!this.containsTemporaryKey(key)) { return null; }

        const value = this.tempCache[key];

        if (FW.isNullOrEmpty(value)) { return null; }

        return value as T;
    }

    public setTemporaryValue(key: string, value: any): void {
        this.tempCache[key] = value;
    }

    public clearTemporaries(): void {
        this.tempCache = {};
    }
}