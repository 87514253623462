import { AuthService } from "./application/auth.service";
import { LoaderService } from "./application/loader.service";
import { APIService } from './application/api.service';
import { MessageService } from './application/message.service';
import { HttpErrorHandler } from './application/httperrorhandler.service';
import { ViewLoader } from './application/viewloader.service';
import { TestService } from './business/test.service';
import { CacheService } from './application/cache.service';
import { ContextService } from './application/context.service';
import { NavigationService } from './application/navigation.service';
import { TipifierService } from './application/typifier.service';
import { SecurityService } from './business/security.service';
import { MailingService } from './business/mailing.service';
import { MessageSwalService } from './application/messageswal.service';
import { SegmentationService } from './business/segmentation.service';
import { DivisionService } from './business/division.service';
import { UserService } from './business/user.service';
import { ScopeService } from './business/scope.service';
import { EmailLogService } from './business/emaillog.service';

export const serviceProviders = [
    APIService,
    AuthService,
    CacheService,
    ContextService,
    HttpErrorHandler,
    LoaderService,
    MailingService,
    MessageService,
    NavigationService,
    SecurityService,
    TestService,
    TipifierService,
    ViewLoader,
    MessageSwalService,
    SegmentationService,
    DivisionService,
    UserService,
    ScopeService,
    EmailLogService
];