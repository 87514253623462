import { Component, OnInit } from '@angular/core';
import { SecurityService } from 'src/app/services/business/security.service';
import { AuthService } from 'src/app/services/application/auth.service';
import { ContextService } from 'src/app/services/application/context.service';
import { SettingsService } from 'src/app/services/business/settings.service';
import * as FW from "../../../framework/core";
import { concatMap, map } from 'rxjs/operators';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { DivisionService } from 'src/app/services/business/division.service';
import { of } from 'rxjs';

declare var $: any;
declare var grecaptcha: any;
@Component({
  selector: 'app-lockscreen',
  templateUrl: './lockscreen.component.html',
  styleUrls: ['./lockscreen.component.scss']
})
export class LockScreenComponent implements OnInit {
  private _userName: string;
  private _userEmail: string;
  private _userPassword: string;
  public captchatoken: string = null;

  public redirectToUrl: string = null;
  public scopeLogo: string;
  public scopeName: string;

  public get userPassword(): string {
    return this._userPassword;
  }
  public set userPassword(v: string) {
    this._userPassword = v;
  }

  public get userName(): string {
    return this._userName;
  }
  public set userName(v: string) {
    this._userName = v;
  }

  public get userEmail(): string {
    return this._userEmail;
  }
  public set userEmail(v: string) {
    this._userEmail = v;
  }

  constructor(private securityService: SecurityService,
    private authService: AuthService,
    private context: ContextService,
    private settingsService: SettingsService,
    private divisionService: DivisionService) { }

  ngOnInit() {
    this.authService.restoreSession();
    this._userName = this.context.authService.userName;
    this._userEmail = this.context.authService.userEmail;

    this.redirectToUrl = this.context.navigation.getQueryParameter("redirectTo");

    this.settingsService.restoreSettings();
    let body = document.getElementById("mainBody");
    
    if(!FW.isNullOrEmpty(body)) body.style.removeProperty("display");

    const scope = this.context.authService.currentScope;
    if (scope) {
      this.scopeLogo = scope.brandImageUrl;
      this.scopeName = scope.name;
    } else this.context.navigation.changeRoute("/login");
  }

  newLogin() {
    this.clearAuthData();
    this.redirectToLogin();
    return false;
  }

  startCaptcha() {
    let self = this;
    grecaptcha.ready(function () {
      grecaptcha.execute('6LdwHssUAAAAAI8dkcZnJ0Wa7SFPi2OjHTjJKqHD', { action: "Login" }).then(function (token) {
        self.captchatoken = token;
      });
    });
  }

  redirectToLogin() {
    this.context.navigation.changeRoute("/login");
  }

  clearAuthData() {
    this.authService.logoff();
  }
  ngAfterViewInit(): void {
    $('.grecaptcha-badge').addClass('show');
    this.startCaptcha();
  }

  public validateLogin() {

    var targetRedirectUrl: string = this.redirectToUrl;

    this.securityService.authenticate(this.context.authService.currentScope.key, this._userEmail, this._userPassword, this.captchatoken)
      .pipe(
        concatMap((loginResult) => {
          if (!FW.isNull(loginResult)) {
            this.context.authService.initSession(
              loginResult.authToken,
              (new Date(loginResult.expiresAt)),
              loginResult.id,
              loginResult.name,
              loginResult.email,
              loginResult.privilege,
              loginResult.role);
            let queryParams: IQueryParams[];
            queryParams = [{ name: '', type: 'url', value: '' }];
            return this.divisionService.search(queryParams).pipe(
              map((division) => {
                if (division) {
                  if (division.content.length > 0) {
                    this.divisionService.divisionList = division.content;
                    this.divisionService.currentDivision = division.content[0];
                  }
                  return loginResult;
                }
              }))
          } else {
            return of(null);
          }
        }),
      )
      .subscribe((loginResult) => {
        if (!FW.isNull(loginResult)) {
          if (FW.isNullOrBlank(targetRedirectUrl)) {
            $('.grecaptcha-badge').removeClass('show');
            this.context.navigation.changeRoute("/main/home");
          } else {
            $('.grecaptcha-badge').removeClass('show');
            this.context.navigation.redirect(targetRedirectUrl);
          }
        }
      });
  }
}
