import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { DivisionService } from '../services/business/division.service';

import * as FW from "../framework/core";

@Injectable()
export class DivisionInterceptor implements HttpInterceptor {

    constructor(private divisionService: DivisionService) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const currentDivision = this.divisionService.currentDivision;
        let divisionReq: HttpRequest<any> = null;

        if (!FW.isNullOrEmpty(currentDivision)) {
            divisionReq = req.clone({ setHeaders: { "x-context-division": currentDivision.id } });
        } else {
            divisionReq = req.clone();
        }

        return next.handle(divisionReq);
    }
}