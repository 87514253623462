import { Component, OnInit, OnChanges, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageIndex: number = 0;
  @Input() pageCount: number = 0;

  @Output() onPageChange = new EventEmitter();

  public pages: Array<number> = null;

  public constructor() {
    this.pages = new Array<number>();
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["pageCount"]) {
      this.pages = new Array<number>();

      for (let i = 0; i < this.pageCount; i++) {
        this.pages.push(i + 1);
      }
    }
  }

  public changePage(index: number): void {
    if (index >= this.pageCount) { index = this.pageCount - 1; }
    if (index < 0) { index = 0; }

    this.onPageChange.emit(index);
  }
}