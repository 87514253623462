import { Injectable } from '@angular/core';

import { IAppMessageHandler } from '../../framework/interfaces';
import { Message, MessageBehavior } from "../../framework/message";

import { Subject, Observable } from 'rxjs';

import { UIBehavior } from "../../ui/ui-behavior";
import { WarningLevel } from '../../framework/enums';

import * as FW from "../../framework/core";

@Injectable({
    providedIn: 'root',
})
export class MessageService implements IAppMessageHandler {
    private messageEmitter: Subject<Message> = null;
    public onMessageReceived: Observable<Message> = null;

    constructor() {
        this.messageEmitter = new Subject<Message>();
        this.onMessageReceived = this.messageEmitter.asObservable();
    }

    public add(msg: Message): void {
        switch (msg.behavior) {
            case MessageBehavior.Log:
                this.writeToConsole(msg);
                break;
            case MessageBehavior.Fixed:
                this.messageEmitter.next(msg);
                break;
            case MessageBehavior.Toast:
                UIBehavior.notify(msg.resolveTitle(), msg.text, this.getType(msg));
                break;
            case MessageBehavior.Popup:
                break;
        }
    }

    private getType(msg: Message): "info" | "success" | "warning" | "error" {
        switch (msg.warningLevel) {
            default:
                return "info";
            case WarningLevel.Success:
                return "success";
            case WarningLevel.Warning:
                return "warning"
            case WarningLevel.Error:
                return "error";
        }
    }

    public clear() {
        this.messageEmitter.next(null);
    }

    private writeToConsole(msg: Message): void {
        switch (msg.warningLevel) {
            default:
                console.log(msg.detail);
                break;
            case WarningLevel.Warning:
                console.warn(msg.detail);
                break;
            case WarningLevel.Error:
                console.error(msg.detail);
                break;
        }
    }
}