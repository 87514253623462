import { WarningLevel } from "./enums";

export enum MessageBehavior {
    None = 0,
    Log = 1,
    Toast = 2,
    Fixed = 3,
    Popup = 4
}

export class Message {
    private _text: string = null;
    private _detail: string = null;
    private _warningLevel: WarningLevel = null;
    private _behavior: MessageBehavior = null;
    private _container: string = null;

    public expanded: boolean = false;

    constructor(container: string, text?: string, detail?: string, warningLevel?: WarningLevel, behavior?: MessageBehavior) {
        this._container = container;
        this._text = text;
        this._detail = detail;
        this._warningLevel = (warningLevel || WarningLevel.Info);
        this._behavior = (behavior || MessageBehavior.Toast);
    }

    public get container(): string {
        return this._container;
    }

    public set container(value: string) {
        this._container = value;
    }

    public get text(): string {
        return this._text;
    }

    public set text(value: string) {
        this._text = value;
    }

    public get detail(): string {
        return this._detail;
    }

    public set detail(value: string) {
        this._detail = value;
    }

    public get warningLevel(): WarningLevel {
        return this._warningLevel;
    }

    public set warningLevel(value: WarningLevel) {
        this._warningLevel = value;
    }

    public get behavior(): MessageBehavior {
        return this._behavior;
    }

    public set behavior(value: MessageBehavior) {
        this._behavior = value;
    }

    public resolveCssClass(): string {
        switch (this.warningLevel) {
            default:
                return "alert-info";
            case WarningLevel.Success:
                return "alert-success";
            case WarningLevel.Warning:
                return "alert-warning";
            case WarningLevel.Error:
                return "alert-danger";
        }
    }

    public resolveTitle(): string {
        switch (this.warningLevel) {
            default:
                return "";
            case WarningLevel.Success:
                return "Pronto!";
            case WarningLevel.Warning:
            case WarningLevel.Error:
                return "Atenção!";
        }
    }

    public toggleDetail(): void {
        this.expanded = !this.expanded;
    }
}