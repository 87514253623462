import { Component, OnInit } from '@angular/core';
import { DivisionService } from 'src/app/services/business/division.service';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { Router } from '@angular/router';
import * as FW from '../../framework/core';
import { IDivision } from 'src/app/models/IDivision';

@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.scss']
})
export class SectorsComponent implements OnInit {
  public sectors: IDivision[];
  public userRole: Number;
  constructor(private divisionService: DivisionService,
    private router: Router) {
    this.userRole = Number(localStorage.getItem("userrole"));
  }

  ngOnInit() {
    this.getSectorList();
    this.divisionService.onDivisionListReceived.subscribe((divisionList) => {
      this.sectors = divisionList;
    })
  }

  private getSectorList() {
    this.sectors = this.divisionService.divisionList;
  }

  public activatedDivision(id: any) {
    return this.divisionService.currentDivision.id == id;
  }

  permissionAdmin() {
    if (this.userRole >= 127) {
      return true;
    }
    return false;
  }

  public changeActivatedDivision(id: any) {
    if (!FW.isNullOrBlank(id)) {
      if (id !== this.divisionService.currentDivision.id) {
        const newDivision: IDivision = this.divisionService.divisionList.find((division) => division.id === id);
        if (!FW.isNullOrEmpty(newDivision)) {
          this.divisionService.currentDivision = newDivision;
          this.router.navigate(['/main/index']);
          window.location.reload()
        }
      }
    }
  }

}
