import { Injectable } from "@angular/core";

import { IAppContext, IValidator } from "../../framework/interfaces";
import { WarningLevel } from "../../framework/enums";
import { Message, MessageBehavior } from "../../framework/message";

import { AuthService } from "./auth.service";
import { MessageService } from "./message.service";
import { NavigationService } from "./navigation.service";
import { TipifierService } from "./typifier.service";
import { CacheService } from "./cache.service";

import * as FW from "../../framework/core";

declare var moment: any;

@Injectable({
    providedIn: 'root',
})
export class ContextService implements IAppContext, IValidator {
    public get operationGroup(): string { return "Generic"; }
    public get messageContainer(): string { return "content-top" }

    private static _context: any = null;
    private static _isolatedCtx: IAppContext = null;

    public get current(): any {
        if (FW.isNull(ContextService._context)) {
            ContextService._context = new Object();
        }
        return ContextService._context;
    }

    constructor(public messages: MessageService, public authService: AuthService, public navigation: NavigationService, public cache: CacheService, public types: TipifierService) {
    }

    public join(group: string): IAppContext {
        if (!FW.isNull(ContextService._isolatedCtx)) {
            const result: IAppContext = {
                current: ContextService._isolatedCtx.current,
                messageContainer: ContextService._isolatedCtx.messageContainer,
                messages: ContextService._isolatedCtx.messages,
                navigation: ContextService._isolatedCtx.navigation,
                operationGroup: group,
                compare: this.compare,
                requestField: this.requestField,
                verifyDate: this.verifyDate
            };
            ContextService._isolatedCtx = null;
            return result;
        } else {
            return {
                current: this.current,
                messageContainer: this.messageContainer,
                messages: this.messages,
                navigation: this.navigation,
                operationGroup: group,
                compare: this.compare,
                requestField: this.requestField,
                verifyDate: this.verifyDate
            };
        }
    }

    public isolate(msgContainer: string, ctx?: any): void {
        ContextService._isolatedCtx = {
            current: ctx,
            messageContainer: msgContainer,
            messages: this.messages,
            navigation: this.navigation,
            operationGroup: this.operationGroup,
            compare: this.compare,
            requestField: this.requestField,
            verifyDate: this.verifyDate
        };
    }

    public requestField(fieldName: string, fieldValue: string | number): boolean {
        if (typeof (fieldValue) == "number") {
            if (FW.isNull(fieldValue)) {
                const validationMsg: Message = new Message(this.messageContainer, `O campo ${fieldName} é obrigatório.`, null, WarningLevel.Warning, MessageBehavior.Fixed);
                this.messages.add(validationMsg);
                return false;
            }
        } else if (FW.isNullOrBlank(fieldValue)) {
            const validationMsg: Message = new Message(this.messageContainer, `O campo ${fieldName} é obrigatório.`, null, WarningLevel.Warning, MessageBehavior.Fixed);
            this.messages.add(validationMsg);
            return false;
        }

        return true;
    }

    public verifyDate(fieldName: string, fieldValue: string): boolean {
        if (FW.isNullOrBlank(fieldValue)) { return true; }
        let testingDate = moment(fieldValue);

        if (!testingDate.isValid()) {
            const validationMsg: Message = new Message(this.messageContainer, `O campo ${fieldName} deve conter uma data válida.`, null, WarningLevel.Warning, MessageBehavior.Fixed);
            this.messages.add(validationMsg);
            return false;
        }

        return true;
    }

    public compare(fieldNameA: string, fieldValueA: string, fieldNameB: string, fieldValueB: string): boolean {
        if (fieldValueA !== fieldValueB) {
            const validationMsg: Message = new Message(this.messageContainer, `O campo ${fieldNameB} não confere com o campo ${fieldNameA}.`, null, WarningLevel.Warning, MessageBehavior.Fixed);
            this.messages.add(validationMsg);
            return false;
        }

        return true;
    }
}