import { Injectable } from "@angular/core";

import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { APIService } from "../application/api.service";

import { ILoginResult } from "../../models/iloginresult";

import { IChangePassword } from "../../models/IChangePassword";

import { IAppContext, IEnvelope } from "../../framework/interfaces";

import * as FW from "../../framework/core";

import { ContextService } from '../application/context.service';
import { IScope } from 'src/app/models/iscope';
import { IForgotPassword } from 'src/app/models/IForgotPassword';

@Injectable({
    providedIn: 'root',
})
export class SecurityService {
    public get serviceName(): string { return "Security" };

    constructor(public context: ContextService, private api: APIService) {
    }

    //#region Authentication

    public authenticate(scope: string, email: string, password: string, captchaValue: string): Observable<ILoginResult> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("E-mail", email);
        validator.requestField("Senha", password);

        if (!validator.isValid) { return of(null); }

        const loginEnvelope = new FW.Envelope({
            email: email,
            password: password,
            scope: scope,
            captchaValue: captchaValue
        });

        return this.api.post(ctx, "/security/authenticate", loginEnvelope).
            pipe(map(this.api.getEnvelopeContent)).
            pipe(map((result: ILoginResult) => {
                if (!FW.isNull(result) && !FW.isNullOrBlank(result.authToken)) {
                    return result;
                } else {
                    return null;
                }
            }));
    }

    public changeNewPassword(password: string, newPassword: string, newPasswordConfirm: string): Observable<IChangePassword> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Senha atual", password);
        validator.requestField("Nova Senha", newPassword);
        validator.requestField("Senha", newPasswordConfirm);

        if (!validator.isValid) { return of(null); }

        const changePasswordEnvelope = new FW.Envelope({
            currentPassword: password,
            newPassword: newPassword,
            newPasswordConfirm: newPasswordConfirm
        });

        return this.api.put(ctx, "/security/user/password/change", changePasswordEnvelope).
            pipe(map(this.api.getEnvelopeContent)).
            pipe(map((result: IChangePassword) => {
                if (!FW.isNull(result) && !FW.isNullOrBlank(result.id)) {
                    return result;
                } else {
                    return null;
                }
            }));
    }

    public getScopeByKey(key: string): Observable<IScope> {
        const url: string = "/security/scope/" + key + "/get";
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.api.get(ctx, url);
    }
    public recoveryPassword(entity: IForgotPassword): Observable<IEnvelope<IForgotPassword>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("E-mail", entity.email);
 
        if (!validator.isValid) { return of(null); }

        const postEnvelope = new FW.Envelope(entity);

        return this.api.post(ctx, '/security/recoverpassword', postEnvelope);
    }
}