import { Component, OnInit, AfterViewInit } from '@angular/core';
import { SecurityService } from 'src/app/services/business/security.service';
import { environment } from 'src/environments/environment';
import { ContextService } from 'src/app/services/application/context.service';
import { SettingsService } from 'src/app/services/business/settings.service';
import * as FW from "../../../framework/core";

import { concatMap, tap, map } from 'rxjs/operators';
import { DivisionService } from 'src/app/services/business/division.service';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { UIBehavior } from 'src/app/ui/ui-behavior';
import { IForgotPassword } from 'src/app/models/IForgotPassword';
import { MessageSwalService } from 'src/app/services/application/messageswal.service';
import { AuthService } from 'src/app/services/application/auth.service';

declare var $: any;
declare var grecaptcha: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  private _userName: string;
  private _email: string;
  private _password: string;
  private _hasLoadedScope: boolean
  private _scopeLogo: string;
  private _scopeName: string;

  public _redirectToUrl: string = null;
  private _scopeKey: string = null;
  public redirectToUrl: string = null;
  public captchatoken: string = null;

  public emailRecoveryPassword: string;

  public get scopeName(): string {
    return this._scopeName;
  }
  public set scopeName(v: string) {
    this._scopeName = v;
  }

  public get scopeLogo(): string {
    return this._scopeLogo;
  }
  public set scopeLogo(v: string) {
    this._scopeLogo = v;
  }

  public get userName(): string {
    return this._userName;
  }
  public set userName(v: string) {
    this._userName = v;
  }


  public get email(): string {
    return this._email;
  }
  public set email(v: string) {
    this._email = v;
  }

  public get password(): string {
    return this._password;
  }
  public set password(v: string) {
    this._password = v;
  }

  public get hasLoadedScope(): boolean {
    return this._hasLoadedScope;
  }
  public set hasLoadedScope(v: boolean) {
    this._hasLoadedScope = v;
  }

  public appVersion: string;

  constructor(private securityService: SecurityService,
    private context: ContextService,
    private divisionService: DivisionService,
    private messageService: MessageSwalService,
    private authService: AuthService) {
    $('.tooltip').remove();
    this.context.authService.logoff();
    this._hasLoadedScope = false;
    this.appVersion = environment.version;
  }


  ngOnInit() {
  }

  ngAfterViewInit(): void {
    $('.grecaptcha-badge').addClass('show');
    this.loadScope();
    this.startCaptcha();
  }

  startCaptcha() {
    let self = this;
    grecaptcha.ready(function () {
      grecaptcha.execute('6LdwHssUAAAAAI8dkcZnJ0Wa7SFPi2OjHTjJKqHD', { action: "Login" }).then(function (token) {
        self.captchatoken = token;
      });
    });
  }

  doLogin() {
    const targetRedirectUrl: string = this.redirectToUrl;
    this.securityService.authenticate(this._scopeKey, this._userName, this._password, this.captchatoken).pipe(
      concatMap((loginResult) => {
        if (!FW.isNull(loginResult)) {
          this.context.authService.initSession(
            loginResult.authToken,
            (new Date(loginResult.expiresAt)),
            loginResult.id,
            loginResult.name,
            loginResult.email,
            loginResult.privilege,
            loginResult.role);

          let queryParams: IQueryParams[];
          queryParams = [{ name: '', type: 'url', value: '' }];
          return this.divisionService.search(queryParams).pipe(
            map((division) => {
              if (division) {
                if (division.content.length > 0) {
                  this.divisionService.divisionList = division.content;
                  this.divisionService.currentDivision = division.content[0];
                }
                return loginResult;
              }
            }))
        }
      }),
    ).subscribe(
      (loginResult) => {
        if (!FW.isNull(loginResult)) {
          if (FW.isNullOrBlank(targetRedirectUrl)) {
            $('.grecaptcha-badge').removeClass('show');
            this.context.navigation.changeRoute("/main/index");
          } else {
            this.context.navigation.redirect(targetRedirectUrl);
          }
        }
      },
      (error) => console.log('Erro ao autenticar usuário')
    )
  }

  public recoveryPassword() {
    let content: IForgotPassword = {
      email: this.emailRecoveryPassword,
      scope: this._scopeKey,
      captchaValue: this.captchatoken
    };

    this.securityService.recoveryPassword(content).subscribe((resp) => {
      if (!FW.isNull(resp)) {
        $('.grecaptcha-badge').removeClass('show');
        $('#modal-password').modal('hide');
        this.messageService.displayMessage({ warninglevel: 0, message: 'E-mail enviado com sucesso' }, () => {
        });
      } else {
        this.messageService.displayMessage({ warninglevel: 1, message: 'Ocorreu um erro ao enviar o E-mail' }, () => {
        });

      }
      this.closeRecoveryPassword();
    })
  }

  public closeRecoveryPassword() {
    this.emailRecoveryPassword = null;
  }

  disableSend() {
    if (this.emailRecoveryPassword && this.IsEmail(this.emailRecoveryPassword)) {
      return false;
    }
    return true;
  }

  public IsEmail(email) {
    let er = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (er.exec(email)) {
      return true;
    } else {
      return false;
    }
  }

  formInvalid() {
    if (!this._userName) return true;
    if (!this.password) return true;
    return false;
  }

  public loadScope(): void {
    let host: string;
    if (environment.production) {
      host = window.location.hostname;
      this._scopeKey = host.replace(".valid.media", "");
    } else {
      if (environment.isHomolog) {
        this._scopeKey = this.context.navigation.getQueryParameter("scope");
      } else {
        host = environment.testEnvironment;
        this._scopeKey = host.replace(".valid.media", "");
      }
    }

    this.securityService.getScopeByKey(this._scopeKey)
      .subscribe((scope) => {
        this.context.authService.currentScope = scope;
        this.scopeLogo = scope.brandImageUrl;

        if (scope && (scope.key == "riobravo" || scope.key === 'validdemonstracao' || scope.key == "hedge")) {
          scope.name = "";
        }
        this.scopeName = scope.name;
        this._hasLoadedScope = true;
        UIBehavior.changeTheme(scope.theme)
      });
  }

}
