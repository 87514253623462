import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { IMailing } from 'src/app/models/IMailing';
import { Observable, of } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";
@Injectable({
  providedIn: 'root'
})
export class MailingService extends BaseService {

  private baseUrl: string;
  public get serviceName(): string { return "Mailing" };
  constructor(private apiService: APIService, public context: ContextService) {
    super();
    this.baseUrl = "/mailing";
  }

  search(params: IQueryParams[]): Observable<FW.Envelope<IMailing[]>> {
    const callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
    const ctx: IAppContext = this.context.join(this.serviceName);
    if (callURL) {
      return this.apiService.get(ctx, callURL)
    } else {
      return of(null)
    }
  }

  public saveMailing(entity: IMailing): Observable<IEnvelope<IMailing>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

    validator.requestField("Nome", entity.name);
    validator.requestField("Arquivo", entity.fileName);

    const postEnvelope = new FW.Envelope(entity);

    if (!validator.isValid) {
      postEnvelope.warningLevel = WarningLevel.Warning;
      return of(postEnvelope);
    }

    return this.apiService.post(ctx, '/domain/mailing/save', postEnvelope);
  }

  public mailingReport(reportType: string, params: IQueryParams[]) {
    const callURL = super.fabricateURL(`${this.baseUrl}/report/${reportType}`, params);
    const ctx: IAppContext = this.context.join(this.serviceName);
    if (callURL) {
      return this.apiService.get(ctx, callURL)
    } else {
      return of(null)
    }
  }


  public getMailingEnriched(id: any): Observable<IEnvelope<IMailing>> {
    const ctx: IAppContext = this.context.join(this.serviceName);
    return this.apiService.get(ctx, `/mailing/downloadmailingenr/${id}`);
  }
}
