import { Injectable } from '@angular/core';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";
@Injectable({
    providedIn: 'root'
})
export class ScopeService extends BaseService {

    private baseUrl: string;
    public get serviceName(): string { return "Scope" };
    constructor(private apiService: APIService, public context: ContextService) {
        super();
        this.baseUrl = "/security/scope";
    }

    search(params: IQueryParams[]): Observable<FW.Envelope<any[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        if (callURL) {
            return this.apiService.get(ctx, callURL)
        } else {
            return of(null)
        }
    }

    public read(segmentationId: string): Observable<any> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/get`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, callURL);
    }

    public exclude(segmentationId: string): Observable<IEnvelope<boolean>> {
        const params: IQueryParams[] = [
            { type: 'url', value: segmentationId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/exclude`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.delete(ctx, callURL);
    }

    public create(entity: any): Observable<IEnvelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Nome", entity.name);
        validator.requestField("Chave", entity.key);
        validator.requestField("Domínio", entity.domain);
        validator.requestField("Conta Mestre", entity.masterAccount);
        validator.requestField("Chave Mestre", entity.masterKey);
        validator.requestField("Provedor de base de dados", entity.databaseProvider);
        validator.requestField("Conexão de Cadeia", entity.connectionString);
        validator.requestField("Provedor", entity.provider);
        validator.requestField("Armazenamento", entity.storage);
        validator.requestField("Cor Base", entity.baseColor);
        validator.requestField("URL da Imagem da Empresa", entity.brandImageUrl);
        validator.requestField("Cor do Tema", entity.theme);


        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/create`, postEnvelope);
    }

    public save(entity: any): Observable<IEnvelope<any>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        // validator.requestField("Nome", entity.name);


        const postEnvelope = new FW.Envelope(entity);

        // if (!validator.isValid) {
        //     postEnvelope.warningLevel = WarningLevel.Warning;
        //     return of(postEnvelope);
        // }

        return this.apiService.post(ctx, `${this.baseUrl}/save`, postEnvelope);
    }

}
