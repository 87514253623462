import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { IQueryParams } from 'src/app/models/IQueryParams';
import { BaseService } from 'src/app/framework/base-service';
import { APIService } from '../application/api.service';
import { IAppContext, IEnvelope } from 'src/app/framework/interfaces';
import { ContextService } from '../application/context.service';
import { Observable, of, Subject } from 'rxjs';
import { WarningLevel } from "../../framework/enums";
import * as FW from "../../framework/core";
import { IDivision } from 'src/app/models/IDivision';



@Injectable({
    providedIn: 'root'
})
export class DivisionService extends BaseService {

    private _currentDivision: IDivision = null;
    private _currentDivisionKey: string = 'currentDivision';
    private _divisionList: IDivision[] = null;
    private _listDivisionKey: string = 'listDivision';
    private baseUrl: string;

    private divisionListEmitter: Subject<IDivision[]> = null;
    public onDivisionListReceived: Observable<IDivision[]> = null;

    public get serviceName(): string { return "Division" };

    public get currentDivision(): IDivision {
        if (FW.isNullOrEmpty(this._currentDivision)) {
            this._currentDivision = JSON.parse(sessionStorage.getItem(this._currentDivisionKey));
        }
        return this._currentDivision;
    }

    public set currentDivision(value: IDivision) {
        if (value != this._currentDivision) {
            sessionStorage.setItem(this._currentDivisionKey, JSON.stringify(value));
            this._currentDivision = value;
        }
    }
    public get divisionList(): IDivision[] {
        if (FW.isNullOrEmpty(this._divisionList)) {
            this._divisionList = JSON.parse(sessionStorage.getItem(this._listDivisionKey));
        }
        return this._divisionList;
    }

    public set divisionList(value: IDivision[]) {
        if (value != this._divisionList) {
            sessionStorage.setItem(this._listDivisionKey, JSON.stringify(value));
            this._divisionList = value;
        }
    }

    constructor(private apiService: APIService, public context: ContextService) {
        super();
        /*
            No backend Division ainda é a entidade Area
        */
        this.baseUrl = "/domain/division";
        this.divisionListEmitter = new Subject<IDivision[]>();
        this.onDivisionListReceived = this.divisionListEmitter.asObservable();
    }


    search(params?: IQueryParams[]): Observable<FW.Envelope<IDivision[]>> {
        const callURL = super.fabricateURL(`${this.baseUrl}/search`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        if (callURL) {
            return this.apiService.get(ctx, callURL)
        } else {
            return of(null)
        }
    }

    public save(entity: IDivision): Observable<IEnvelope<IDivision>> {
        const ctx: IAppContext = this.context.join(this.serviceName);
        const validator: FW.ValidationContext = new FW.ValidationContext(ctx);

        validator.requestField("Nome", entity.name);
        validator.requestField("Arquivo", entity.brandImageUrl);

        const postEnvelope = new FW.Envelope(entity);

        if (!validator.isValid) {
            postEnvelope.warningLevel = WarningLevel.Warning;
            return of(postEnvelope);
        }

        return this.apiService.post(ctx, `${this.baseUrl}/save`, postEnvelope);
    }

    public read(divisionId: string): Observable<IDivision> {
        const params: IQueryParams[] = [
            { type: 'url', value: divisionId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/read`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.get(ctx, callURL);
    }

    public exclude(divisionId: string): Observable<IEnvelope<boolean>> {
        const params: IQueryParams[] = [
            { type: 'url', value: divisionId, name: '' }
        ]
        const callURL = super.fabricateURL(`${this.baseUrl}/exclude`, params);
        const ctx: IAppContext = this.context.join(this.serviceName);
        return this.apiService.delete(ctx, callURL);
    }

    public refreshDivisionList() {
        const queryParams: IQueryParams[] = [{ name: '', type: 'url', value: '' }];
        this.search(queryParams).subscribe((divisionList) => {
            this.divisionList = divisionList.content;
            this.divisionListEmitter.next(this.divisionList);
        })
    }
}
